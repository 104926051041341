<template>
  <div>
    <div v-if="cities && cities.length > 0" class="city--wrapper">
      <!--      <vue-tabs class="tabs&#45;&#45;district" active-tab-color="#ffffff" centered>-->
      <!--        <v-tab-->
      <!--          :title="$t(`directory.district.${propertyType}`)"-->
      <!--          v-for="propertyType in propertyTypes"-->
      <!--          :key="propertyType"-->
      <!--          class="tab&#45;&#45;content"-->
      <!--        >-->
      <!--          <div class="row">-->
      <!--            <div class="col-6 p-1" v-for="district in districts" :key="district.id">-->
      <!--              >-->
      <!--              <router-link :to="districtPath(propertyType, district)">{{-->
      <!--                district.name-->
      <!--              }}</router-link>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </v-tab>-->
      <!--      </vue-tabs>-->
      <div class="row">
        <div class="col-6 p-1" v-for="city in cities" :key="city.id">
          >
          <router-link :to="cityPath(city)">{{ capitalize(city.name) }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { VTab, VueTabs } from 'vue-nav-tabs';
// import 'vue-nav-tabs/themes/vue-tabs.css';
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';
export default {
  // components: {
  //   VueTabs,
  //   VTab,
  // },
  mixins: [HelperMixin],
  computed: {
    ...mapState({
      propertyTypes: (state) => state.v2.masters.propertyTypes,
      cities: (state) => state.v2.directory.cities,
    }),
  },
  methods: {
    cityPath(city) {
      let propertyType = this.$route.params.propertyType;
      if (city && city.name) {
        return encodeURI(
          '/cari/' +
            propertyType +
            '/' +
            city.name.toLowerCase().replace('-', '__').split(' ').join('-').replace(/\//g, '') +
            '?category=city&category_filter_id=' +
            city.id,
        );
      } else {
        return encodeURI('/cari/' + propertyType);
      }
    },
  },
};
</script>
